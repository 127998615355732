<template>
  <div>
    <v-chip-group
      v-model="currentTag"
      active-class="primary text--white"
      column
    >
      <v-chip
        v-for="(tag, n) in tags" :key="n"
        class="ma-2"
        @click="filterTags(tag)"
      >
        <div class="caption">
          {{ tagStr(tag.tag) }}
        </div>
      </v-chip>
    </v-chip-group>
  </div>

</template>

<script>
export default {
  name: 'Tags',
  props: ['tags'],
  data: () => ({
    currentTag: null
  }),
  computed: {
    getTagList () {
      const tmp = []
      if (this.tags !== undefined && this.tags !== null) {
        for (const key in this.tags) {
          tmp.push(this.tags[key])
        }
      }
      return tmp
    }
  },
  methods: {
    tagStr (tag) {
      return tag.replace('_', ' ')
    }
  }
}
</script>
