<template>
  <v-container fluid>
    404! Page not found.
  </v-container>
</template>

<script>

export default {
  name: 'NotFound404'
}
</script>
