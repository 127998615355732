<template>
  <v-container>
    <entry-card :entry="entryEdit" :maxWidth="getVCardWidth"/>
    <v-slider
      v-model="vcardWidth"
      label="EntryCard Width"
      min="50"
      max="500"
      thumb-label="always"
    />
    <v-card >
      <v-form ref="form" >
        <v-text-field
          v-model="entryEdit.title"
          label="Article Title"
        />
        <v-text-field
          v-model="entryEdit.short_desc"
          label="Short Description"
        />
        <v-text-field
          v-model="entryEdit.image_url"
          label="Image Url"
        />
        <v-text-field
          v-model="entryEdit.image_alt"
          label="Image Alt"
        />
        <v-checkbox
          v-model="entryEdit.visible"
          label="Visible"
        ></v-checkbox>

        <v-combobox
          v-if="tagList !== null && tagList !== undefined"
          v-model="entryEdit.tags"
          :items="getTags"
          label="Tags"
          item-text="tag"
          item-value="id"
          multiple
          dense
        ></v-combobox>
      </v-form>
    </v-card>
    <v-card class="pa-2">
      <app-alert/>
      <v-card-actions>
        <v-btn
          @click="closeEntry()"
          color="info"
          class="pa-5"
        >Close</v-btn>

        <v-btn
          @click="saveEntry()"
          color="green"
          class="pa-5"
        >Save</v-btn>
      </v-card-actions>
    </v-card>
    <v-card >
      <div
        v-if="entrySectionsList !== undefined
          && entrySectionsList.length > 0"
        >
        <v-row>
          <v-col class="mb-4">

            <div
              v-for="(sectionData, n) in entrySectionsList"
              :key="n"
              class="mt-3"
            >
              <section-edit
                :startDialog="startDialog"
                caption="Edit"
                :sectionIdx="sectionData.idx"
              />
              <entry-section :sectionData="sectionData" />
            </div>

          </v-col>
        </v-row>
      </div>
      <section-edit
        :startDialog="startDialog"
        caption="New Section"
        :sectionIdx="null"
      />

      <app-alert/>
      <v-card-actions>
        <v-btn
          @click="closeEntry()"
          color="info"
        >Close</v-btn>

        <v-btn
          @click="saveEntry()"
          color="green"
        >Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ENTRIES_URL } from '@/constants/urls'

import EntryCard from '@/components/EntryCard'
import EntrySection from '@/components/EntrySection'
import SectionEdit from '@/components/SectionEdit'

export default {
  name: 'EntryEdit',
  components: {
    'entry-card': EntryCard,
    'entry-section': EntrySection,
    'section-edit': SectionEdit
  },
  props: ['entryId'],
  data: () => ({
    startDialog: false,
    vcardWidth: 300
  }),

  mounted () {
    const entryID = this.entryId
    const entry = this.getEntry({ id: entryID })
    if (entry !== undefined) {
      this.setEntryEdit(entry)
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      error: 'flag/error',
      getEntry: 'entry/getEntry',
      getEntryEdit: 'entry/getEntryEdit',
      getSectionEdit: 'entry/getSectionEdit',
      getEditFlag: 'entry/getEditFlag',
      getTags: 'entry/getTags',
      getQueryResult: 'qry/getQueryResult',
      getQueryList: 'qry/getQueryList'
      // getNavMenu: 'nav/getNavMenu',
      // getTabActive: 'nav/getTabActive'
    }),
    /* not only true/false for dialog, must be the right type
     * new versus existing section
     * also, should hide other dialog if one is true
     */
    entrySection: {
      get () { return this.getSectionEdit },
      set (val) { this.setSectionEdit(val) }
    },
    entryEdit: {
      get () { return this.getEntryEdit },
      set (val) { this.setEntryEdit(val) }
    },
    getVCardWidth () {
      return this.vcardWidth + 'px'
    },
    entrySectionsList () {
      const tmp = []
      const edit = this.entryEdit
      if (edit !== undefined && edit !== null) {
        if (edit.article !== undefined) {
          // iterate through sections
          const keys = Object.keys(edit.article)
            .sort((a, b) => a - b)
          for (let i = 0; i < keys.length; i++) {
            tmp.push(edit.article[keys[i]])
          }
        }
      }
      return tmp
    },
    tagList () {
      const res = this.getTags
      if (res !== null && res !== undefined) {
        return res
      }
      return null
    }
  },

  methods: {
    ...mapActions({
      // loads query for tags
      loadQuery: 'qry/loadQuery',
      // clears after saving edit
      clearEntryEdit: 'entry/clearEntryEdit',
      clearSectionEdit: 'entry/clearSectionEdit',

      // used with v-model for section
      setSectionEdit: 'entry/setSectionEdit',

      newSectionEdit: 'entry/newSectionEdit',

      // used with v-model
      setEntryEdit: 'entry/setEntryEdit',

      // saves to db
      saveEntryEdit: 'entry/saveEntryEdit',
      loadEntriesTags: 'qry/loadEntriesTags'
    }),
    addSection () {
      this.newSectionEdit()
    },
    saveEntry () {
      const entry = JSON.parse(JSON.stringify(this.entryEdit))

      this.saveEntryEdit(
        {
          url: ENTRIES_URL,
          data: this.convertToDb(entry)
        }
      )
      // this.closeEntry()
      // this.loadEntriesTags()
    },

    closeEntry () {
      // add check for unsaved data
      this.clearEntryEdit()
      this.loadEntriesTags()
      this.$router.push('/dashboard')
    },
    convertToDb (entry) {
      /* example
          {
            "idx": 0,
            "title": "Here is the Title",
            "tags": [],
            "visible": true,
            "article": {
              0: {      <==   section idx
                "columns": {
                  "0": {
                    "colWidth": 6,
                    "title": "This is the <br>First Entry",
                    "text": [
                      "Here is an example of text."
                    ],
                    "image": {
                      "alt": "my image",
                      "src": "/img/1/southbend_orig1.jpg",
                      "height": "500px"
                    }
                  },
                  "1": {
                    "colWidth": 6,
                    "title": "",
                    "text": [
                      "Here is a an example of text."
                    ],
                    "image": {
                      "alt": "",
                      "src": "",
                      "height": "500px"
                    }
                  }
                }
              }
            }
          }
      */
      const entryId = entry.id !== undefined
        ? entry.id
        : null

      const dbEntry = {
        id: entryId,
        title: entry.title,
        image_url: entry.image_url,
        image_alt: entry.image_alt,

        // created_at (default)
        // slug
        visible: entry.visible,
        short_desc: entry.short_desc,
        article: entry.article,
        tags: entry.tags

      }
      return dbEntry
    }
  }
}
</script>
