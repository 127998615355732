<template>
  <v-app>
    <v-navigation-drawer temporary fixed>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item
            v-if="userIsAuthenticated"
            @click="onSignout"
          >
            <v-icon left>mdi-exit-to-app</v-icon>
            <v-list-item-content>
              Signout
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      dark
      color="primary"
    >
      <v-menu v-if="$vuetify.breakpoint.name === 'xs'">
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon
            @click,stop="on"
            v-bind="attrs"
            v-on="on"
            class="d-md-none"
          >
        </v-app-bar-nav-icon>
        </template>
        <v-list nav dense>
        <v-list-item-group>
          <v-list-item to="/projects">
            <v-icon left>
              mdi-text-box-multiple-outline
            </v-icon>
            <v-list-item-content>
              Projects
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/about">
            <v-icon left>mdi-account</v-icon>
            <v-list-item-content>
              About
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="userIsAuthenticated"
            @click="onSignout"
          >
            <v-icon left>mdi-exit-to-app</v-icon>
            <v-list-item-content>
              Signout
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      </v-menu>
      <v-toolbar-title>
        <router-link to="/" style="cursor: pointer">
        <v-img
          :src="$vuetify.theme.dark
                ? '/img/logo_dark.png'
                : '/img/logo2.png'"

        />
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-xs-only" >

        <v-btn
          v-for="(item, idx) in toolbarItems"
          :key="idx"
          :to="item.link"
          color="#adadad"
          text
          plain
          >
          <v-icon left>{{ item.icon }}</v-icon>
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>

      <v-toolbar-items v-if="userIsAuthenticated">
        <v-btn
          text
          @click="onSignout"
        >
        <v-icon left>mdi-exit-to-app</v-icon>
        Sign Out
        </v-btn>
      </v-toolbar-items>
        <v-btn
          text
          @click="changeTheme()"
          color="#adadad"
        >
          <v-icon left>
            mdi-cog-outline
          </v-icon>
          {{ ModeDesc }}
        </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { track, parameters } from 'insights-js'
import {
  mdiExitToApp,
  mdiTextBoxMultipleOutline,
  mdiCogOutline,
  mdiAccount,
  mdiLockOpenOutline
} from '@mdi/js'

import { mapGetters, mapActions } from 'vuex'

import { ACCESS_TOKEN } from '@/constants/params'

export default {
  name: 'App',

  data: () => ({
    navMenu: false,
    mode: 0,
    'mdi-exit-to-app': mdiExitToApp,
    'mdi-text-box-multiple-outline': mdiTextBoxMultipleOutline,
    'mdi-cog-outline': mdiCogOutline,
    'mdi-account': mdiAccount,
    'mdi-lock-open-outline': mdiLockOpenOutline

  }),

  computed: {
    ...mapGetters({
      user: 'auth/user',
      userIsAuthenticated: 'auth/userIsAuthenticated'
    }),
    toolbarItems () {
      let items = []
      if (this.userIsAuthenticated) {
        items = []
      } else {
        items = [
          { icon: 'mdi-account', title: 'About', link: '/about' },
          { icon: 'mdi-lock-open-outline', title: 'Sign In', link: '/signin' }
        ]
      }
      return items
    },
    showIcon () {
      return this.$vuetify.breakpoint.mobile
    },
    ModeDesc () {
      return this.$vuetify.theme.dark
        ? 'Light'
        : 'Dark'
    }
  },
  methods: {
    ...mapActions({
      signout: 'auth/signout'
    }),
    onSignout () {
      this.signout()
      localStorage.removeItem(ACCESS_TOKEN)
      if (this.$router.currentRoute !== '/') {
        this.$router.push('/')
      }
    },
    changeTheme () {
      track({
        id: 'set-theme',
        parameters: {
          themeType: this.$vuetify.theme.dark
            ? 'Dark'
            : 'Light',
          locale: parameters.locale(),
          screenSize: parameters.screenType()
        }
      })
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    }
  }
}
</script>
