<template>
  <v-card flat>
    <v-card>
      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
           <tr>
              <td width="175px"
              >URL</td>
              <td
                class="font-weight-bold"
              >
                {{ getCurrentMethod.url }}
              </td>
            </tr>
            <tr>
              <td>
                Requirements (Method Decorators)
              </td>
              <td
                class="font-weight-bold"
              >
                {{ requirements }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
        </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { showRequirements } from '../../lib/common'

export default {
  name: 'MethodHeading',
  props: ['modelClass'],
  computed: {
    ...mapGetters({
      getCurrentMethod: 'meta/getCurrentMethod'
    }),
    requirements () {
      const req = JSON.parse(
        JSON.stringify(
          showRequirements(
            this.getCurrentMethod.requirements
          )
        )
      )
      return req.length > 0 ? req : 'None'
    }
  }
}
</script>
