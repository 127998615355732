<template>
  <v-container fluid fill-height="100%">
    <signin-app/>
  </v-container>
</template>

<script>
import Signin from '@/components/Signin'

export default {
  components: {
    'signin-app': Signin
  }
}
</script>
