<template>
  <v-card
    :width="formWidth"
    min-width="400px"
    class="pa-5"
  >
    <v-row>
      <v-col>
        <div> Column Number: {{ columnIdx }}</div>
      </v-col>
      <v-col>
        <v-btn
          text
          color="blue darken-1"
          align="right"
          @click="deleteCol(columnIdx)"
        >
          <v-icon>mdi-delete-forever</v-icon>
          Delete
        </v-btn>
      </v-col>
    </v-row>
    <v-form ref="form"
      :width="formWidth"
    >
      <v-text-field
        v-model="column.colWidth"
        label="Column Width"
        :rules="widthRules"
        :width="formWidth"
      />

      <v-checkbox
        v-model="showTitle"
        label="Title"
      ></v-checkbox>

      <v-text-field
        v-if="showTitle"
        v-model="column.title"
        label="Title"
      />

      <v-checkbox
        v-model="showText"
        label="Text"
      ></v-checkbox>

      <v-card
        v-if="showText"
      >
        <v-card-title>Paragraphs</v-card-title>
        <v-textarea
          v-for="(para, n) in column.text"
          :key="n"
          v-model="column.text[n]"
          label="Line"
          auto-grow
          clearable
          color="#6b6b6b"
        />

        <v-card
          align="right"
          color="#4d4d4d"
        >
            <v-btn
              @click="addPara()"
            >
              Add
            </v-btn>
            <v-btn
              @click="delPara()"
            >
              Delete
            </v-btn>
        </v-card>
      </v-card>

      <v-checkbox
        v-model="showImage"
        label="Image"
      ></v-checkbox>
      <v-card
        v-if="showImage"
      >
        <v-text-field
          v-model="column.image.src"
          label="Image src"
        />
        <v-text-field
          v-model="column.image.alt"
          label="Image alt"
        />
        <v-text-field
          v-model="column.image.height"
          label="Image height"
        />
      </v-card>
    </v-form>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mdiDeleteForever } from '@mdi/js'

export default {
  props: [
    'columnIdx'
  ],
  data: () => ({
    colWidth: 4,
    title: null,
    imagePath: null,
    paras: [],
    curPara: null,
    formWidth: '600px',
    showTitle: false,
    showImage: false,
    showText: false,
    'mdi-delete-forever': mdiDeleteForever
  }),

  mounted () {
    if (this.column !== null && this.column !== undefined) {
      this.showTitle = this.column.title !== ''
      this.showText = this.column.text.length > 0
      this.showImage = this.column.image.src !== ''
    }
  },

  computed: {
    ...mapGetters({
      getColumnEdit: 'entry/getColumnEdit',
      getSectionEdit: 'entry/getSectionEdit'
    }),
    column: {
      get () { return this.getColumnEdit({ idx: this.columnIdx }) },
      set (val) { this.setColumnEdit({ idx: val }) }
    },
    widthRules () {
      // NOTE: this doesn't work yet
      // const columns = this.getSectionEdit.columns
      // let max = 12
      // for (const key in columns) {
      //   if (key !== this.columnIdx) {
      //     max -= columns[key].colWidth
      //   }
      // }
      return [
        value => !!value || 'Required.',
        value => {
          const pattern = /[0-9]{1,3}/
          return pattern.test(value) || 'Invalid number'
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      setColumnEdit: 'entry/setColumnEdit',
      deleteColumnEdit: 'entry/deleteColumnEdit'
    }),
    addPara () {
      this.column.text.push('')
      this.curPara = this.curPara !== null ? this.curPara + 1 : 1
    },
    delPara () {
      this.column.text.pop()
      this.curPara = this.curPara === 1 ? null : this.curPara - 1
    },
    deleteCol (columnIdx) {
      this.deleteColumnEdit({ idx: columnIdx })
    }
  }
}
</script>
