<template>
  <v-card class="float-auto-right">
    <div
      v-for="project in getProjects"
      :key="project.name"
    >
      <v-card-title>
        {{ project.name }}
      </v-card-title>

      <v-card-text
        v-for="(line, n) in project.description"
        :key="n"
      >
        {{ line }}
      </v-card-text>

      <v-card-actions>
        <v-btn
          :to="project.to"
          text
        >read more</v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
// import { track, parameters } from 'insights-js'
import { mapGetters } from 'vuex'

export default {
  name: 'ProjectList',
  computed: {
    ...mapGetters({
      getProjects: 'proj/getProjects'
    })
  },
  methods: {
    projectTo (project) {
      // track({
      //   id: 'projects-page',
      //   parameters: {
      //     locale: parameters.locale(),
      //     screenSize: parameters.screenType()
      //   }
      // })
      this.$router.push(project.to)
    }
  }
}
</script>
