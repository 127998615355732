 <template>
  <v-alert
    class="error"
    dismissible @input="onClose"
    :value="true"
    v-if="asText"
  >
    {{ asText }}
  </v-alert>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  watch: {
    error (val) {
      if (val !== null) {

      }
    }
  },
  computed: {
    ...mapGetters({
      error: 'flag/error'
    }),
    asText () {
      const error = JSON.parse(JSON.stringify(this.error))
      var text = ''
      if (error !== null && error !== undefined) {
        if (Array.isArray(error.message)) {
          for (var i = 0; i < this.text.length; i++) {
            const item = this.text[i]
            const key = Object.keys(item)[0]
            text += key + ': ' + item[key] + ' '
          }
        } else {
          text = error.message
        }
      }
      return text
    }
  },
  methods: {
    ...mapActions({
      clearError: 'flag/clearError',
      setLoading: 'flag/setLoading'
    }),
    onClose () {
      this.$emit('dismissed')
      this.clearError()
      this.setLoading(false)
    }
  }
}
</script>
