import Vue from 'vue'
import Vuex from 'vuex'
import flagStore from './modules/flags'
import authStore from './modules/auth'
import queryStore from './modules/queries'
import entrieStore from './modules/entries'
import navMenuStore from './modules/navmenu'
import projectStore from './modules/projects'

// projects
import metaStore from '../vue-meta-info/src/store/modules/meta'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    qry: queryStore,
    auth: authStore,
    flag: flagStore,
    entry: entrieStore,
    nav: navMenuStore,
    proj: projectStore,
    meta: metaStore
  }
})
