import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  navMenu: [],
  navHeadings: [],
  breadcrumbs: [],
  curNav: [
    { title: 'Test', link: '/tests' }
  ],
  tabActive: null
}

const mutations = {
  setNavMenu (state, payload) {
    state.navMenu = payload
  },
  clearNavMenu (state) {
    state.navMenu = []
  },
  loadCurNav (state, payload) {
    state.curNav = payload
  },
  clearCurNav (state) {
    state.curNav = {}
  },
  loadBreadcrumbs (state, payload) {
    state.breadcrumbs = payload
  },
  clearBreadcrumbs (state) {
    state.breadcrumbs = []
  },
  setTabActive (state, payload) {
    state.tabActive = payload
  }
}

const actions = {
  loadBreadcrumbs ({ commit }, breadcrumbs) {
    commit('loadBreadcrumbs', breadcrumbs)
  },
  clearBreadcrumbs ({ commit }) {
    commit('clearBreadcrumbs')
  },
  loadCurNav ({ commit }, nav) {
    commit('loadCurNav', nav)
  },
  setTabActive ({ commit }, value) {
    commit('setTabActive', value)
  },
  setNavMenu ({ commit }, payload) {
    commit('setNavMenu', payload)
  }
}

const getters = {
  navMenuLoaded (state) {
    if (state.navMenu.length > 0) {
      return true
    }
  },
  getNavMenu (state) {
    return state.navMenu
  },
  breadcrumbs (state) {
    return state.breadcrumbs
  },
  curNav (state) {
    return state.curNav
  },
  getTabActive (state) {
    return state.tabActive
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
