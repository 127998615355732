/* entries.js
 *
 * entries are pulled as a full query, since not that many
 * editing
 *  entryEdit
 *    copy entry to edit
 *       section edit:
 *          sections are in a list
 *          copies to section edit are by index number
 *          saving a section replaces back by index
 *       column edit
 *          columns are in a list
 *          copies to column edit are by index number
 *          saving a column replaces by index
 *
 *    save entry edit to db
 *    reload/refresh all entries
 *
 * sections and columns are identified by idx rather than id
 * this is to keep separate the concept that they are by
 * positional index.
 */
import Vue from 'vue'
import Vuex from 'vuex'

import { getHeader, axiosError } from '@/lib/common'
import { ACCESS_TOKEN } from '@/constants/params'

Vue.use(Vuex)
// Vue.prototype.$http = axios
const axios = Vue.axios

const state = {

  entries: {}, // keyed by id
  entriesSummary: {},
  tags: [],

  entryEdit: {}, // keyed by section index
  sectionEdit: {}, // keyed by index

  editId: null, // may not be needed

  // flag can be ['entry', 'section']
  editFlag: {}
}

export const mutations = {
  // payload.id is the key that identifies the object in store
  clearEntries (state, payload) {
    delete state.entries[payload.id]
  },
  setEntriesSummary (state, payload) {
    state.entries = {}
    for (const key in payload.data.results) {
      const entry = payload.data.results[key]
      Vue.set(state.entriesSummary, entry.id, entry)
    }
  },
  setEntry (state, payload) {
    Vue.set(state.entries, payload.data.id, payload.data)
  },
  setEntries (state, payload) {
    state.entries = {}
    for (const key in payload.data.results) {
      const entry = payload.data.results[key]
      Vue.set(state.entries, entry.id, entry)
    }
  },
  setTags (state, payload) {
    // skips paging
    state.tags = payload.data.results
  },
  clearEntryEdit (state) {
    state.entryEdit = {}
    state.sectionEdit = {}
    state.columnEdit = {}
  },

  clearSectionEdit (state) {
    state.sectionEdit = {}
  },
  setEntryEdit (state, payload) {
    state.entryEdit = payload
  },
  setSectionEdit (state, payload) {
    state.sectionEdit = payload
  },
  loadSectionEdit (state, payload) {
    state.sectionEdit = state.entryEdit.article[payload.idx]
  },
  setColumnEdit (state, payload) {
    Vue.set(
      state.sectionEdit.columns,
      payload.idx,
      payload.column
    )
  },

  deleteColumnEdit (state, payload) {
    delete state.sectionEdit.columns[payload.idx]

    const newColumns = {}
    const keys = Object.keys(state.sectionEdit.columns)
    for (let i = 0; i < keys.length; i++) {
      newColumns[i] = state.sectionEdit.columns[keys[i]]
    }
    Vue.set(state.sectionEdit, 'columns', newColumns)
  },

  saveSectionEdit (state) {
    /* transfer to entryEdit
     * Example of section edit:
     * {
     *    idx: 0,
     *    columns: {
     *      0: {
     *        colWidth: 12,
     *        title: 'Title of New Entry',
     *        text: ['Here is a an example of text.'],
     *        image: {
     *          alt: 'my image',
     *          src: '/img/1/southbend_orig1.jpg',
     *          height: '500px'
     *        }
     *      }
     *  }
     *
     */
    Vue.set(
      state.entryEdit.article,
      state.sectionEdit.idx,
      JSON.parse(JSON.stringify(state.sectionEdit))
    )
    state.sectionEdit = {}
  },

  setEditFlag (state, payload) {
    Vue.set(state.editFlag, state.editId, payload.value)
  },
  setEditId (state, payload) {
    state.editId = payload.id
  },
  clearEditId (state) {
    state.editId = null
  },
  deleteSectionEdit (state, payload) {
    delete state.entryEdit.article[payload.idx]
  }
}

export const actions = {

  clearEditId ({ commit }) {
    commit('clearEditId')
  },
  setEditId ({ commit }, payload) {
    commit('setEditId', payload)
  },

  clearEntryEdit ({ commit }, payload) {
    commit('clearEntryEdit', payload)
  },

  setEntryEdit ({ commit }, payload) {
    commit('setEntryEdit', payload)
  },

  setEditFlag ({ commit }, payload) {
    commit('setEditFlag', payload)
  },

  clearSectionEdit ({ commit }) {
    commit('clearSectionEdit')
  },

  loadSectionEdit ({ commit }, payload) {
    commit('loadSectionEdit', payload)
  },

  newSectionEdit ({ commit }, payload) {
    commit('setSectionEdit', payload)
  },

  setSectionEdit ({ commit }, payload) {
    if (payload !== undefined) {
      commit('setSectionEdit', payload)
    }
  },

  deleteColumnEdit ({ commit }, payload) {
    commit('deleteColumnEdit', payload)
  },

  clearColumnEdit ({ commit }) {
    commit('clearColumnEdit')
  },

  setColumnEdit ({ commit }, payload) {
    commit('setColumnEdit', payload)
  },

  saveSectionEdit ({ commit }) {
    commit('saveSectionEdit')
  },

  saveEntryEdit ({ commit }, payload) {
    // for saving a record received in payload
    commit('flag/setLoading', true, { root: true })
    commit('flag/clearError', null, { root: true })
    let method
    let url = payload.url
    if (payload.data.id !== null && payload.data.id !== undefined) {
      method = 'put'
      url += payload.data.id + '/'
    } else {
      method = 'post'
    }

    axios({
      method: method,
      url: url,
      data: payload.data,
      headers: getHeader(localStorage.getItem(ACCESS_TOKEN))
    })
      .then(response => {})
      .catch(error => {
        if (error.response) {
        } else if (error.request) {
        } else {
        }
        axiosError(commit, error)
      })
  },
  deleteSectionEdit ({ commit }, payload) {
    commit('deleteSectionEdit', payload)
    commit('clearSectionEdit')
  }

}

export const getters = {

  getEntries: (state) => (payload) => {
    // payload not used yet
    return state.entries
  },

  getEntriesSummary: (state) => (payload) => {
    // payload not used yet
    return state.entriesSummary
  },

  getEntry: (state) => (payload) => {
    return state.entries[payload.id]
  },

  getEntryEdit (state) {
    return state.entryEdit
  },

  getSectionEdit (state) {
    return state.sectionEdit
  },

  getColumnEdit: (state) => (payload) => {
    if (state.sectionEdit.columns !== undefined) {
      return state.sectionEdit.columns[payload.idx]
    } else {
      return null
    }
  },

  getEditFlag: (state) => (payload) => {
    // payload is just a string type
    // unlike setEditFlag
    return state.editFlag[payload]
  },
  getTags (state) {
    return state.tags
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
