/* flags.js
 * for setting flags regarding the status of processes
 */
import Vue from 'vue'

const state = {
  loading: false,
  error: null,
  flag: {}
}

const mutations = {
  setLoading (state, payload) {
    state.loading = payload
  },
  setError (state, payload) {
    // format: {message: error}
    state.error = payload
  },
  clearError (state) {
    state.error = null
  },
  setFlag (state, payload) {
    Vue.set(state.flag, payload.id, payload.value)
  },
  clearFlag (state, payload) {
    delete state.flag[payload.id]
  }
}

const actions = {
  clearError ({ commit }) {
    commit('clearError')
  },
  setError ({ commit }, payload) {
    commit('setError', payload)
  },
  setLoading ({ commit }, payload) {
    commit('setLoading', payload)
  },
  setFlag ({ commit }, payload) {
    commit('setFlag', payload)
  },
  clearFlag ({ commit }, payload) {
    commit('clearFlag', payload)
  }
}

const getters = {
  loading (state) {
    return state.loading
  },
  error (state) {
    return state.error
  },
  getFlag: (state) => (payload) => {
    return state.flag[payload.id]
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
