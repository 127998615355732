<template>
  <v-container fluid class="pa-0 pb-2">
    <v-card
      class="tile  pl-3" dark>
      <v-simple-table
        dense
        v-if="navMenu !== undefined"
        max-height="800px"

      >
        <template v-slot:default>
          <tbody>
            <tr
              v-for="(item, i) in navMenu"
              :key="i"
              @click="setTabActive(i)"
            >
              <td >{{ item.title }}</td>
            </tr>
          </tbody>
         </template>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      active: null
    }
  },
  mounted () {
    this.setTabActive('0')
  },
  computed: {
    ...mapGetters({
      curNav: 'nav/curNav',
      breadcrumbs: 'nav/breadcrumbs',
      getTabActive: 'nav/getTabActive',
      navMenu: 'nav/navMenu'
    }),
    tabActive: {
      get () { return this.getTabActive },
      set (val) { this.setTabActive(val) }
    }
  },
  methods: {
    ...mapActions({
      setTabActive: 'nav/setTabActive'
    })
  }
}
</script>
