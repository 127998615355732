var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',[_c('v-card',[(_vm.user !== null)?_c('v-card-title',[_vm._v(" Section: "+_vm._s(_vm.sectionData.idx)+" ")]):_vm._e()],1),_c('v-row',{attrs:{"align":"center"}},_vm._l((_vm.columnList),function(column,i){return _c('v-col',{key:i,attrs:{"md":column.colWidth,"lg":column.colWidth,"cols":"12"}},[((
            column.title !== ''
            && column.title !== null
          ) || (
            column.text !== undefined
            && column.text.length > 0
          ))?_c('v-card',{staticClass:"px-5",attrs:{"fill-height":"","elevation":"0","color":_vm.color !== undefined ? _vm.color : null}},[(column.title !== undefined
            && column.title !== ''
            && column.title !== null)?_c('v-card-text',[(_vm.sectionData.idx == 0)?_c('h1',{class:_vm.titleClass(_vm.sectionData.idx, column)},[_c('div',{domProps:{"innerHTML":_vm._s(column.title)}})]):_vm._e(),(_vm.sectionData.idx != 0)?_c('h2',{class:_vm.titleClass(_vm.sectionData.idx, column)},[_c('div',{domProps:{"innerHTML":_vm._s(column.title)}})]):_vm._e()]):_vm._e(),(column.text !== undefined && column.text.length > 0)?_c('div',_vm._l((column.text),function(line,j){return _c('span',{key:j,attrs:{"align":"center"}},[_c('v-row',[_c('v-card-text',{staticClass:"py-1 my-2"},[(_vm.hasCode(line))?_c('div',[_c('vue-code-highlight',{attrs:{"language":_vm.mdLang(line)}},[_c('pre',[_vm._v("                      "),_c('code',[_vm._v("\n                      "+_vm._s(_vm.mdLine(line))+"\n                      ")]),_vm._v("\n                    ")])])],1):_vm._e(),(!_vm.hasCode(line))?_c('div',{domProps:{"innerHTML":_vm._s(_vm.compiledMarkdown(line))}}):_vm._e()])],1)],1)}),0):_vm._e()],1):_vm._e(),_c('v-card',{attrs:{"fill-height":"","elevation":"0"}},[(column.image.src !== undefined && column.image.src !== '')?_c('v-img',{attrs:{"src":column.image.src,"alt":column.image.alt,"height":column.image.height,"contain":""}}):_vm._e()],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }