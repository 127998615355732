<template>
  <v-container>
    <v-card
      class="mx-auto"
    >
      <v-card-title class="text-h3" >Vue-Meta-Info</v-card-title>

      <div
        v-for="(line, n) in intro"
        :key="'intro-' + n"
      >
        <v-card-text class="py-1" v-html="getLine(line)"/>
      </div>
      <classes/>
      <resources/>
      <v-card-title class="text-h4">The Problem</v-card-title>
      <div
        v-for="(line, n) in problem"
        :key="'prob-' + n"
      >
        <v-card-text class="py-1" v-html="getLine(line)"/>
      </div>

      <v-card-title class="text-h4">A Different Approach</v-card-title>
      <div
        v-for="(line, n) in approach"
        :key="'app-' + n"
      >
        <v-card-text class="py-1" v-html="getLine(line)"/>
      </div>

      <v-card-title class="text-h4">An Example</v-card-title>
      <div
        v-for="(line, n) in examples"
        :key="'ex-' + n"
      >
        <v-card-text class="py-1" v-html="getLine(line)"/>
      </div>

      <v-card-title class="text-h5">Model Code</v-card-title>
      <div
        v-for="(line, n) in models"
        :key="'mod-' + n"
      >
        <v-card-text class="py-1" v-html="getLine(line)"/>
      </div>

      <v-card-text>
        <vue-code-highlight language="python">
          <pre >
            <code>
              {{ codeModels }}
            </code>
          </pre>
        </vue-code-highlight>
      </v-card-text>

      <v-card-title class="text-h5">Resource Code</v-card-title>
      <div
        v-for="(line, n) in resources"
        :key="'res-' + n"
      >
        <v-card-text class="py-1" v-html="getLine(line)"/>
      </div>

      <v-card-text>
        <vue-code-highlight language="python">
          <pre >
            <code>
              {{ codeResources }}
            </code>
          </pre>
        </vue-code-highlight>
      </v-card-text>

      <v-card-title class="text-h5">Meta Information</v-card-title>
      <div
        v-for="(line, n) in meta"
        :key="'meta-' + n"
      >
        <v-card-text class="py-1" v-html="getLine(line)"/>
      </div>

      <v-card-text>
        <vue-code-highlight language="python">
          <pre >
            <code>
              {{ codeMeta }}
            </code>
          </pre>
        </vue-code-highlight>
      </v-card-text>

      <v-card-title class="text-h5">API URLS</v-card-title>
      <div
        v-for="(line, n) in urls"
        :key="'url-' + n"
      >
        <v-card-text class="py-1" v-html="getLine(line)"/>
      </div>

      <v-card-text>
        <vue-code-highlight language="python">
          <pre >
            <code>
              {{ codeUrls }}
            </code>
          </pre>
        </vue-code-highlight>
      </v-card-text>

    </v-card>
  </v-container>
</template>

<script>
import { track, parameters } from 'insights-js'

import { mapGetters } from 'vuex'
import { component as VueCodeHighlight } from 'vue-code-highlight'
import marked from 'marked'

import Resources from './Resources'
import Classes from './Classes'

// import ClassTable from '../components/ClassTable'

export default {
  name: 'VueMetaInfo',
  components: {
    VueCodeHighlight,
    Classes,
    Resources
  },

  data: () => ({
    classTab: 0,
    classTabs: [
      { tab: 'Customer' },
      { tab: 'Invoice' },
      { tab: 'Invoice Tab' }
    ],
    customerTab: 0,
    invoiceTab: 0,
    invoiceItemTab: 0,
    intro: [
      'This app provides an example of presenting meta information about backend resources created by Flask-RESTful-DBBase. By introspecting the models and generated resources, it can provide:',
      '* Detailed model information.',
      '* Resource endpoints for each HTTP method.',
      'Selecting the class or resource shows the details discovered from either the DBBase models created or the Flask-RESTful-DBBase resources.',
      'More details can be found for each package mentioned can be found here.',
      '* [SQLAlchemy](https://www.sqlalchemy.org/)',
      '* [Flask-SQLAlchemy](https://flask-sqlalchemy.palletsprojects.com/en/2.x/)',
      '* [Flask-RESTful](https://flask-restful.readthedocs.io/en/latest/)',
      '* [DBBase](https://github.com/sidorof/dbbase)',
      '* [Flask-RESTful-DBBase](https://github.com/sidorof/flask-restful-dbbase)',
      '* [Vue-Meta-Info](https://github.com/sidorof/vue-meta-info)',
      'Below shows meta information gathered from a simple set of database models: customer, invoice, and invoice items. By selecting one of the classes, the details of the table and the relationships are illustrated.',
      'After the classes, the resources are shown in similar fashion.'
    ],
    problem: [
      'The start of Flask-RESTful-DBBase arose from several sources.',
      '* I liked how SQLAlchemy worked, and I have a body of work that uses it extensively. There is a level of nuance to it that I like better than the top-down approaches, such as Swagger.',
      '* I liked the approach that Flask-SQLAlchemy took to embedding the query function within the Model. While I am aware that SQLAlchemy finds the method to be abhorrent, I like the convenience of it. Django has a similar (but different) approach to handling it this way as well. ',
      '* Flask-RESTful had an approach to serialization that they basically abandoned in favor of Marshmallow. However, in either case, validating and/or returning data requires duplication of work that is necessary to create the SQLAlchemy models in the first place just to get something out. Django also has serializers that must be configured. Since you have already defined your tables, it just seems rude to require validation all over again. Your software should be able to introspect what the models are about and act accordingly.',

      '* Having a body of work that used SQLAlchemy, but ran outside of the Flask environment, the methods and objects did not match the objects as Flask-SQLAlchemy does it. It makes sense to be able to define your models once regardless of the environment.'
    ],
    approach: [
      'To take into account these concerns, the following steps were required:',

      '* Enable a common interface for SQLAlchemy models within the Flask environment and without. That requires you to pick a side for the syntax. I created a wrapper that embodied the syntax used with Flask-SQLAlchemy. The project for this is [DBBase](https://github.com/sidorof/dbbase)',

      '* To avoid duplicative work for serialization that meant enabling serialization features within that wrapper. Serialization arises automatically from the table definitions. Serialization can also be specified directly. Data flowing out of a method uses defaults that arise from model creation. To avoid awkward lock-in, serialization can modified at the model level, resource level, or method level. Data flowing into a method is automatically validated using serialization as well. And, it automatically filters unwanted JSON variables avoiding a necessary clean-up by the front-end application prior to posting.',

      '* Flask-RESTful-DBBase uses DBBase models for model definitions. By default, the serialization comes from the DBBase models. It is possible to tweak or override them if necessary. The package wraps itself around Flask-SQLAlchemy and Flask-RESTful, leaving the session connections to Flask-SQLAlchemy.',
      '* Vue-Meta-Approach suggests a way of presenting details about the models and their relationships. And, it presents meta information about the resources and endpoints created.'
    ],
    examples: [
      'This example uses a minimal set of models. It consists of:',
      '* Customer\n* Invoice\n* Invoice Items\n',
      'The resources are generated from Flask-RESTful-DBBase introspecting the models, and providing the corresponding endpoints.'
    ],
    models: [
      'Here are the models as defined in Flask-RESTful-DBBase. They look like standard Flask-SQLAlchemy models.',
      'A side note, the `extended_amount` in the invoice item will be included in serialization outflows, and automatically filtered out from inflows. `Extended amount` is a function, not a database variable.'
    ],
    codeModels: '\nclass Customer(db.Model):\n    __tablename__ = "customer"\n    id = db.Column(db.Integer, nullable=True, primary_key=True)\n    name = db.Column(db.String, nullable=False)\n\n    invoices = db.relationship("Invoice", backref="customer")\n\n\nclass Invoice(db.Model):\n    __tablename__ = "invoice"\n\n    id = db.Column(db.Integer, nullable=True, primary_key=True)\n    customer_id = db.Column(\n        db.Integer, db.ForeignKey("customer.id"), nullable=False\n    )\n    invoice_date = db.Column(db.Date, nullable=False)\n\n    invoice_items = db.relationship("InvoiceItem", backref="invoice")\n\n\nclass InvoiceItem(db.Model):\n    __tablename__ = "invoice_item"\n\n    id = db.Column(db.Integer, nullable=True, primary_key=True)\n    invoice_id = db.Column(\n        db.Integer, db.ForeignKey("invoice.id"), nullable=False\n    )\n    part_code = db.Column(db.String, nullable=False)\n    units = db.Column(db.Integer, nullable=False)\n    unit_price = db.Column(db.Float(precision=2), nullable=False)\n\n    def extended_amount(self):\n         return units * unit_price\n\ndb.create_all()',

    resources: [
      'To expose the endpoints, the resource classes are created. A resource is created for each single resource and each collection. Note that the url prefix is used in this case so that a typical api path is used. Each resource has the class assigned to it. The full URL path is automatically generated by default from the model name. Again, all of this can be modified as necessary,'
    ],
    codeResources: '\nURL_PREFIX = "/api/v1\n\n"class CustomerResource(ModelResource):\n    model_class = Customer\n    url_prefix = URL_PREFIX\n\n\nclass CustomerCollectionResource(CollectionModelResource):\n    model_class = Customer\n    url_prefix = URL_PREFIX\n\n\nclass InvoiceResource(ModelResource):\n    model_class = Invoice\n    url_prefix = URL_PREFIX\n\n    # necessary only if the database does not understand\n    #   dates in string form -- such as Sqlite3\n    use_date_conversions = True\n\n\nclass InvoiceCollectionResource(CollectionModelResource):\n    model_class = Invoice\n    url_prefix = URL_PREFIX\n\n\nclass InvoiceItemResource(ModelResource):\n    model_class = InvoiceItem\n    url_prefix = URL_PREFIX\n\n\nclass InvoiceItemCollectionResource(ModelResource):\n    model_class = InvoiceItem\n    url_prefix = URL_PREFIX\n',
    meta: [
      'To create the meta information, we just feed the resources into meta resource classes'
    ],
    codeMeta: '\nclass CustomerMeta(MetaResource):\n    resource_class = CustomerResource\n\n\nclass CustomerCollectionMeta(MetaResource):\n    resource_class = CustomerCollectionResource\n\n\nclass InvoiceMeta(MetaResource):\n    resource_class = InvoiceResource\n\n\nclass InvoiceCollectionMeta(MetaResource):\n    resource_class = InvoiceCollectionResource\n\n\nclass InvoiceItemMeta(MetaResource):\n    resource_class = InvoiceItemResource\n\n\nclass InvoiceItemCollectionMeta(MetaResource):\n    resource_class = InvoiceItemCollectionResource\n',
    urls: [
      'The resources and meta resources are added to the api.',
      'The classes and resources as shown at the top of the page then become available via the API. Below is the list of URLs that have been created.'
    ],
    codeUrls: '\n/api/v1/customers \n/api/v1/customers/<int:id> \n/api/v1/invoice-items \n/api/v1/invoice-items/<int:id> \n/api/v1/invoices \n/api/v1/invoices/<int:id> \n/api/v1/meta \n/api/v1/meta/classes \n/api/v1/meta/classes/<string:name> \n/api/v1/meta/customers/collection \n/api/v1/meta/customers/single \n/api/v1/meta/invoice-items/single \n/api/v1/meta/invoices/collection \n/api/v1/meta/invoices/single'
  }),

  mounted () {
    track({
      id: 'projects-page',
      parameters: {
        locale: parameters.locale(),
        screenSize: parameters.screenType()
      }
    })
  },

  computed: {
    ...mapGetters({
      getProjects: 'proj/getProjects',
      getClass: 'meta/getClass'
    }),
    project () {
      const projects = this.getProjects
      return projects[0]
    }
  },
  methods: {
    getLine (line) {
      return marked(line)
    }
  }
}
</script>
