import Vue from 'vue'
import './plugins/axios'
// import '@mdi/font/css/materialdesignicons.css'
import vuetify from './plugins/vuetify'

import VueCodeHighlight from 'vue-code-highlight'
// import 'prism-es6/components/prism-javascript'
import 'vue-code-highlight/themes/duotone-sea.css'
import 'prism-es6/components/prism-markup-templating'
import 'prism-es6/components/prism-python'
import 'prism-es6/components/prism-c'
// import 'vue-code-highlight/themes/prism-dark.css'
// import 'vue-code-highlight/themes/prism-coy.css'
// import 'vue-code-highlight/themes/prism-twilight.css'
// import 'vue-code-highlight/themes/window.css'

import AlertCmp from '@/components/Alert'

import App from './App.vue'
import TabNav from '@/components/TabNav'

import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.use(VueCodeHighlight)
Vue.component('tab-nav', TabNav)

Vue.config.productionTip = false
Vue.component('app-alert', AlertCmp)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
