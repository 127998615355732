<template>
  <v-col >
    <v-card
    >
      <v-card-title v-if="user !== null">
        Section: {{sectionData.idx }}
      </v-card-title>
    </v-card>

    <v-row align="center">
      <v-col
        v-for="(column, i) in columnList"
        :key="i"
        :md="column.colWidth"
        :lg="column.colWidth"
        cols="12"

      >
        <v-card
          v-if="(
              column.title !== ''
              && column.title !== null
            ) || (
              column.text !== undefined
              && column.text.length > 0
            )"
          class="px-5"
          fill-height
          elevation="0"
          :color="color !== undefined ? color : null"
        >
          <v-card-text
            v-if="column.title !== undefined
              && column.title !== ''
              && column.title !== null"
          >
            <h1
              v-if="sectionData.idx == 0"
              :class="titleClass(sectionData.idx, column)"
            >
              <div v-html="column.title"></div>
            </h1>

            <h2
              v-if="sectionData.idx != 0"
              :class="titleClass(sectionData.idx, column)"
            >
              <div v-html="column.title"></div>
            </h2>
          </v-card-text>
          <div v-if="column.text !== undefined && column.text.length > 0">
            <span
              v-for="(line, j) in column.text"
              :key="j"
              align="center"
            >
              <v-row>
              <v-card-text class="py-1 my-2">
                <div v-if="hasCode(line)">
                    <vue-code-highlight :language="mdLang(line)">
                      <pre >
                        <code>
                        {{ mdLine(line) }}
                        </code>
                      </pre>
                    </vue-code-highlight>
                </div>
                <div
                  v-if="!hasCode(line)"
                  v-html="compiledMarkdown(line)"
                />
              </v-card-text>
              </v-row>
            </span>
          </div>
        </v-card>
        <v-card fill-height elevation="0">
          <v-img
            v-if="column.image.src !== undefined && column.image.src !== ''"
              :src="column.image.src"
              :alt="column.image.alt"
              :height="column.image.height"
              contain
          ></v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import marked from 'marked'

import { component as VueCodeHighlight } from 'vue-code-highlight'

import { mapGetters } from 'vuex'

export default {
  name: 'EntrySection',
  props: ['entryId', 'entryTitle', 'slug', 'sectionData', 'color'],
  components: {
    VueCodeHighlight
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    columnList () {
      const tmp = []
      if (this.sectionData !== undefined) {
        // NOTE: sort this?
        const columns = this.sectionData.columns
        for (const colKey in columns) {
          tmp.push(columns[colKey])
        }
      }
      return tmp
    }
  },
  methods: {
    compiledMarkdown (line) {
      return marked(line)
        .replaceAll('<a ', '<a class="primary--text" ')
    },
    hasCode (text) {
      return (text.startsWith('```'))
    },
    mdLang (line) {
      return line.split('\n')[0].substring(3)
    },
    mdLine (line) {
      const tmp = []
      const text = line.split('\n')
      for (let i = 1; i < text.length - 1; i++) {
        tmp.push(text[i])
      }
      return tmp.join('\n')
    },
    dfltTitleClass (idx) {
      const width = this.$vuetify.breakpoint.width
      const items = [
        'font-weight-bold',
        'text-center',
        'gray--text',
        'text-break'
      ]
      if (idx === 0) {
        if (width <= 700) {
          items.push('display-1')
        } else if (width < 800) {
          items.push('display-2')
        } else {
          items.push('display-3')
        }
      }
      return items.join(' ')
    },
    titleClass (idx, column) {
      if (column.titleClass !== null && column.titleClass !== undefined) {
        return column.titleClass
      }
      this.dfltTitleClass(idx)
    }
  }
}
</script>

<style lang="scss">
  @import '~vuetify/src/components/VStepper/_variables.scss';

  .custom-class {
    padding: $stepper-step-padding;
  }
</style>
