export const BASE_URL = process.env.VUE_APP_ROOT_API
export const API = BASE_URL + '/api'

export const ENTRIES_URL = API + '/entries/'
export const ENTRIES_SUMMARY_URL = API + '/entries-summary/'
export const TAGS_URL = API + '/tags/'

// export const SIGNIN_URL = API + '/signin'
export const SIGNIN_URL = API + '/token/'
export const SIGNOUT_URL = API + '/signout'

export const TOKEN_URL = API + '/token/'
export const REFRESH_TOKEN_URL = API + '/token/refresh/'

export const ALL_URLS = [
  SIGNIN_URL,
  SIGNOUT_URL,
  REFRESH_TOKEN_URL
]
