<template>
  <v-card
    height="100%"
    :maxWidth="maxWidth"
    minWidth="200px"
    elevation="0"
    :to="toMore(entry)"
  >
    <div height="150px">
      <v-list-item two-line>
        <v-list-item-content>
          <div class="text-overline mb-4">
            {{ entry.short_desc }}
          </div>
          <v-list-item-title
            class="text-md-h5 text-sm-body-1 mb-1"
          >
            {{ entry.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
    <v-img
      v-if="entry.image_url !== undefined && entry.image_url !== ''"
      :src="entry.image_url"
      :alt="entry.image_alt"
      contain
      eager
      height="170px"
      class="mx-3"
    />
    <tag-list :tags="entry.tags"/>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Tags from '@/components/Tags'

export default {
  name: 'EntryCard',
  components: { 'tag-list': Tags },
  props: ['entry', 'maxWidth'],
  computed: {
    ...mapGetters({
      user: 'auth/user',
      getEntry: 'entry/getEntry'
    })
  },
  methods: {
    ...mapActions({
      loadQuery: 'qry/loadQuery'
    }),

    toMore (entry) {
      const user = this.user
      const url = user !== null && user !== undefined
        ? '/entry-edit/' + entry.id
        : '/entries/' + entry.id + '/' + entry.slug
      return url
    }
  }
}
</script>
