<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="9">
        <entry-cards/>
      </v-col>
      <v-col cols="12" md="3">
        <v-card-title @click="toProjects()" style="cursor: pointer">
            <v-icon left>
              mdi-text-box-multiple-outline
            </v-icon>
            Projects
        </v-card-title>
        <v-divider/>
        <project-list/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiTextBoxMultipleOutline } from '@mdi/js'

import { TAGS_URL, ENTRIES_SUMMARY_URL } from '@/constants/urls'
import { mapActions } from 'vuex'

import Entries from '@/components/Entries'
import ProjectList from '@/components/ProjectList'

export default {
  name: 'Home',
  components: {
    'entry-cards': Entries,
    'project-list': ProjectList
  },
  data: () => ({
    'mdi-text-box-multiple-outline': mdiTextBoxMultipleOutline
  }),
  created () {
    this.loadQuery(
      {
        id: 'entries',
        url: ENTRIES_SUMMARY_URL,
        params: {},
        mutation: 'entry/setEntriesSummary'
      }
    )
    this.loadQuery(
      {
        id: 'tags',
        url: TAGS_URL,
        params: {},
        mutation: 'entry/setTags'
      }
    )
  },
  methods: {
    ...mapActions({
      loadQuery: 'qry/loadQuery'
    }),
    toProjects () {
      this.$router.push('/projects')
    }
  }
}
</script>
