<template>
  <v-container>
    <v-card
      width="200px"
    >
      <v-breadcrumbs
      v-if="breadcrumb.show"
        :items="breadcrumb.items"
        divider=">"
      ></v-breadcrumbs>
    </v-card>

    <v-card class="float-auto-right">
      <v-card-title id="projects-title">
          <v-icon left>
            mdi-text-box-multiple-outline
          </v-icon>
          Projects
      </v-card-title>
      <v-divider/>
      <project-list/>
    </v-card>
  </v-container>
</template>

<script>
import { mdiTextBoxMultipleOutline } from '@mdi/js'
import { track, parameters } from 'insights-js'

import { mapGetters } from 'vuex'
import ProjectList from '@/components/ProjectList'

export default {
  name: 'Projects',
  components: { ProjectList },
  data: () => ({
    'mdi-text-box-multiple-outline': mdiTextBoxMultipleOutline,
    breadcrumb: {
      show: true,
      items: [
        {
          text: 'home',
          disabled: false,
          to: '/'
        },
        {
          text: 'projects',
          disabled: true,
          to: '/projects'
        }
      ]
    }
  }),
  mounted () {
    track({
      id: 'projects-page',
      parameters: {
        locale: parameters.locale(),
        screenSize: parameters.screenType()
      }
    })
  },
  computed: {
    ...mapGetters({
      getProjects: 'proj/getProjects'
    })
  }
}
</script>
