import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import SigninPage from '@/views/SigninPage'
import AuthGuard from './auth-guard'

import Entry from '@/components/Entry'
import About from '@/views/About'
import EntryEdit from '@/components/EntryEdit'
import NotFound404 from '@/components/NotFound404'

import Resources from '@/vue-meta-info/src/views/Resources'
import Classes from '@/vue-meta-info/src/views/Classes'
import VueMetaInfo from '@/vue-meta-info/src/views/VueMetaInfo.vue'
import Projects from '@/views/Projects'

Vue.use(VueRouter)

const routes = [
  {
    path: '/signin',
    name: 'SigninPage',
    component: SigninPage
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/projects/vue-meta-info',
    name: 'VueMetaInfo',
    component: VueMetaInfo
  },
  {
    path: '/projects/vue-meta-info/resources',
    name: 'Resources',
    component: Resources
  },
  {
    path: '/projects/vue-meta-info/classes',
    name: 'Classes',
    component: Classes
  },
  {
    path: '/entries/:entryId/:slug',
    name: 'Entry',
    component: Entry,
    props: true
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard'),
    beforeEnter: AuthGuard
  },
  {
    path: '/entry-edit/:entryId',
    name: 'EntryEdit',
    component: EntryEdit,
    props: true,
    beforeEnter: AuthGuard
  },
  {
    path: '*',
    name: 'NotFound404',
    component: NotFound404
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
