import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      dark: {
        // primary: '#08325e',
        // secondary: '#0f56a3',
        // accent: colors.red.base
        primary: '#0f5dac',
        secondary: colors.grey.darken3,
        accent: colors.lightGreen.base,
        error: colors.deepOrange.base,
        warning: colors.yellow.base,
        info: colors.teal.darken4,
        success: colors.green.base
      },
      light: {
      //  primary: '#4b6572',
      //  accent: colors.red.base
        // primary: colors.blue.darken2,
        primary: '#08325e',
        secondary: colors.grey.base,
        accent: colors.lime.base,
        error: colors.red.base,
        warning: colors.orange.base,
        info: colors.teal.base,
        success: colors.green.base
      }
    },
    dark: false,
    light: true
  },
  icons: {
    iconfont: 'mdiSvg'
  },
  breakpoint: {
    mobileBreakpoint: 'sm' // This is equivalent to a value of 960
  }
})
