<template>
  <v-card color="secondary" flat class="mx-auto " width="380px">
    <app-alert/>
    <v-row row>
      <v-col cols="12">
        <v-card >
            <div :class="fontSize" >Sign-In</div>
            <v-card-text>
              <form @submit.prevent="onSignin" class="pb-4">

                <v-row>
                  <v-col cols="10" offset="1">
                    <v-text-field
                      name="username"
                      label="User Name"
                      id="username"
                      v-model="username"
                      type="text"
                      required
                      primary--text
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="10" offset="1">
                    <v-text-field
                      name="password"
                      label="Password"
                      id="password"
                      v-model="password"
                      type="password"
                      required
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="10" offset="1">
                     <v-btn type="submit"
                        color="info"
                        rounded
                        :loading="loading"
                        @click.native="loader = 'loading'"
                        :disabled="loading"
                        >
                        Sign in
                        <span slot="loader" class="custom-loader">
                            <v-icon light>cached</v-icon>
                        </span>
                      </v-btn>
                  </v-col>
                </v-row>

                </form>

            </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { track, parameters } from 'insights-js'
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      username: '',
      password: ''
    }
  },
  watch: {
    user (value) {
      if (value !== null && value !== undefined) {
        this.$router.push('/dashboard')
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      loading: 'flag/loading'
    }),
    fontSize () {
      const padding = ' my-2 pt-2'
      const suffix = ' text-center grey--text'
      const width = this.$vuetify.breakpoint.width
      if (width <= 300) {
        return 'text-h5' + suffix
      } else if (width < 700) {
        return 'text-h4' + suffix
      } else if (width < 800) {
        return 'text-h3' + suffix
      } else {
        return 'text-h2' + padding + suffix
      }
    }
  },
  methods: {
    ...mapActions({
      signin: 'auth/signin'
    }),
    onSignin () {
      const user = {
        username: this.username,
        password: this.password
      }
      track({
        id: 'attempted-signin',
        parameters: {
          locale: parameters.locale(),
          screenSize: parameters.screenType()
        }
      })
      this.signin(user)
      // this.$router.push('/dashboard')
    }
  }
}
</script>
