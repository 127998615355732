import Vue from 'vue'
import Vuex from 'vuex'
// import router from '@/router'

import {
  SIGNIN_URL, SIGNOUT_URL // , REFRESH_TOKEN_URL
} from '@/constants/urls'

import { ACCESS_TOKEN } from '@/constants/params'
// import { getHeader, axiosError } from '@/lib/common'
import { axiosError, getHeader } from '@/lib/common'

Vue.use(Vuex)
// Vue.prototype.$http = axios
const axios = Vue.axios

const state = {
  user: null,
  topics: [],
  registrationStatus: null,
  registerResponse: null
}

const mutations = {
  loadTopics (state, payload) {
    state.topics = payload
  },
  clearTopics (state) {
    state.topics = []
  },
  setUser (state, payload) {
    state.user = payload
  },
  clearUser (state) {
    state.user = null
  },
  setTokens (status, payload) {
    // goes to localStorage, not state
    localStorage.setItem(ACCESS_TOKEN, payload.token)
    // localStorage.setItem(REFRESH_TOKEN, payload.refresh_token)
  }
}

const actions = {
  signin ({ commit }, payload) {
    commit('flag/setLoading', true, { root: true })
    commit('flag/clearError', null, { root: true })
    const headers = getHeader()

    axios({
      method: 'post',
      url: SIGNIN_URL,
      data: payload,
      headers: headers
    })
      .then(response => {
        commit('setTokens', response.data)
        commit('flag/setLoading', false, { root: true })
        const currentUser = {
          username: payload.username
        }
        commit('setUser', currentUser)
        // commit('nav/setNavMenu', menu, { root: true })
        // not functional yet
        // commit('loadTopics', response.data.topics)
        // router.push('/dashboard')
      })
      .catch(function (error) {
        axiosError(commit, error)
      })
  },
  signout ({ commit }) {
    const token = localStorage.getItem('ACCESS_TOKEN')
    commit('clearUser')
    localStorage.removeItem(ACCESS_TOKEN)
    commit('flag/clearError', null, { root: true })
    axios({
      method: 'post',
      url: SIGNOUT_URL,
      data: null,
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then(response => {})
      .catch(error => {
        axiosError(commit, error)
      })
    commit('flag/setLoading', false, { root: true })
  }
}

const getters = {
  user (state) {
    return state.user
  },
  userIsAuthenticated (state) {
    return state.user !== null && state.user !== undefined
  },
  topics (state) {
    return state.topics
  },
  isAdmin (state) {
    for (var i = 0; i < state.topics.length; i++) {
      const topic = state.topics[i]
      if (topic === 'admin') {
        return true
      }
    }
  },
  getRegistrationStatus (state) {
    return state.registrationStatus
  },
  getRegisterResponnse (state) {
    return state.registerResponse
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
