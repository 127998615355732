<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="450px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="info"
        v-bind="attrs"
        v-on="on"
      >
        {{ caption }}
      </v-btn>
    </template>

    <v-card
      class="pa-4"
    >
      <v-row>
        <v-col>
          <v-card-title>
            <span class="headline">Section<br>Edit</span>
          </v-card-title>
        </v-col>
        <v-col>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="blue darken-1"
              text
              @click="delSection()"
            >
              Delete
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="closeEdit()"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="saveSection()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
      <v-divider
        inset
      ></v-divider>
      <v-text-field
        v-model="sectionData.idx"
        label="Section Index"
      ></v-text-field>
      <v-tabs
        v-model="tab"
      >
        <v-tab
          v-for="(column, i) in columnsList"
          :key="i"
        >
          Column {{ i }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="(column, i) in columnsList"
          :key="i"
        >
          <column-edit :columnIdx="i"/>
        </v-tab-item>
      </v-tabs-items>

      <v-card>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="addColumn()"
          >
            Add Column
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-row>
        <v-col>
          <v-card color="#acacac">
            <v-card-title>Raw Data</v-card-title>
            <v-card
              v-if="sectionData !== undefined"
              color="#6b6b6b" >
              {{ sectionData }}
            </v-card>
          </v-card>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="closeEdit()"
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="saveSection()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getDfltSection } from '@/lib/common'

import ColumnEdit from './ColumnEdit'

export default {
  name: 'SectionEdit',
  props: ['startDialog', 'caption', 'sectionIdx'],
  components: { 'column-edit': ColumnEdit },
  data: () => ({
    dialog: false,
    tab: 0
  }),

  watch: {
    dialog (val) {
      if (val) {
        if (val) {
          if (this.sectionIdx !== null) {
            this.loadSectionEdit({ idx: this.sectionIdx })
          } else {
            this.newSection()
          }
        }
        this.tab = 0
      }
    }
  },

  computed: {
    ...mapGetters({
      getEditFlag: 'entry/getEditFlag',
      getSectionEdit: 'entry/getSectionEdit',
      getEntryEdit: 'entry/getEntryEdit'
    }),

    editFlag: {
      get () { return this.getEditFlag },
      set (val) { this.setEditFlag({}) }
    },

    sectionData: {
      get () { return this.getSectionEdit },
      set (val) { this.setSectionEdit(val) }
    },
    columnsList () {
      // NOTE: this is a dupe of EntrySection
      const tmp = []
      if (this.sectionData !== undefined) {
        // NOTE: sort this?
        const columns = this.sectionData.columns
        for (const colKey in columns) {
          tmp.push(columns[colKey])
        }
      }
      return tmp
    }
  },
  methods: {
    ...mapActions({
      clearEntryEdit: 'entry/clearEntryEdit',
      setEditFlag: 'entry/setEditFlag',
      newSectionEdit: 'entry/newSectionEdit',
      loadSectionEdit: 'entry/loadSectionEdit',
      setSectionEdit: 'entry/setSectionEdit',
      saveSectionEdit: 'entry/saveSectionEdit',
      clearSectionEdit: 'entry/clearSectionEdit',
      setColumnEdit: 'entry/setColumnEdit',
      deleteSectionEdit: 'entry/deleteSectionEdit'
    }),
    newSection () {
      const keys = Object.keys(this.getEntryEdit.article)
      const newKey = keys.length > 0
        ? Math.max(...keys) + 1
        : 0

      this.newSectionEdit(getDfltSection(newKey))
      this.sectionData = this.getEntryEdit.article[newKey]
    },
    saveSection () {
      this.setSectionEdit(this.sectionData)
      this.saveSectionEdit()
      this.closeEdit()
    },
    delSection () {
      this.deleteSectionEdit({ idx: this.sectionData.idx })
      this.closeEdit()
    },
    closeEdit () {
      this.setEditFlag({ section: null })
      this.clearSectionEdit()
      this.dialog = false
    },
    addColumn () {
      const keys = Object.keys(this.sectionData.columns)
      const newKey = keys.length > 0
        ? Math.max(...keys) + 1
        : 0
      this.setColumnEdit(
        {
          idx: newKey,
          column: {
            colWidth: 6,
            title: '',
            text: [],
            image: { alt: '', src: '', height: '500px' }
          }
        }
      )
    }
  }
}
</script>
