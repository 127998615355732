<template>
  <v-container>
    <v-row >
      <v-col
        v-for="(entry, i) in entriesList"
        :key="i"
        sm="6"
        md="4"
        cols="12"
      >
        <entry-card :entry="entry"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex'
import EntryCard from '@/components/EntryCard'

export default {
  name: 'Entries',
  components: { 'entry-card': EntryCard },
  data: () => ({
    currentTag: null
  }),
  computed: {
    ...mapGetters({
      getEntriesSummary: 'entry/getEntriesSummary'
    }),
    entriesList () {
      const tmp = []
      if (this.getEntriesSummary({}) !== null && this.getEntriesSummary({}) !== undefined) {
        const entries = this.getEntriesSummary({})
        let keys = Object.keys(entries)
        keys = keys.sort((a, b) => (b - a))
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i]
          tmp.push(entries[key])
        }
      }
      return tmp
    }
  }
}
</script>
