<template>
  <v-container>
    <v-card
      width="200px"
    >
      <v-breadcrumbs
      v-if="breadcrumb.show"
        :items="breadcrumb.items"
        divider=">"
      ></v-breadcrumbs>
    </v-card>
    <div class="display-3 pa-5">
      Don Smiley
    </div>
    <v-row align="start">
      <v-col cols="12" md="8">
        <v-card
          v-for="(section, n) in sectionList"
          :key="n"
          class="align-self-auto"
        >
          <entry-section :sectionData="section"/>
        </v-card>
        <v-card class="mt-2 px-4" >
          <v-card-title>
            Technologies
          </v-card-title>
          <v-row>
            <v-col
              v-for="(column, n) in technologies.columns"
              :key="n"
              cols="12"
              md="3"
              class="px-3 mx-2"
            >
              <v-simple-table
                dense
              >
                <template v-slot:default>
                  <thead >
                    <tr height="50px">
                      <th class="text-left subtitle-2 font-weight-bold">
                        {{ technologies.columns[n].title }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in technologies.columns[n].items"
                      :key="item.name"
                    >
                      <td>{{ item.name }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card
          fill-height
          class="d-flex align-self-start"
          align-self-auto
        >
          <entry-section :sectionData="site[0]" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { track, parameters } from 'insights-js'

import EntrySection from '@/components/EntrySection'

export default {
  name: 'About',
  props: ['entryId', 'sectionLimit'],
  components: { EntrySection },

  data: () => ({
    breadcrumb: {
      show: true,
      items: [
        {
          text: 'home',
          disabled: false,
          to: '/'
        },
        {
          text: 'about',
          disabled: true,
          to: '/about'
        }
      ]
    },
    sectionList: [
      {
        id: 0,
        columns: {
          0: {
            colWidth: 12,
            title: 'About Me',
            text: [
              'I have a history in software development.',
              'I am very interested in artificial intelligence, machine learning, and robotics on both the software side as well as hardware. As part of that interest i have also focussed on APIs, backend technologies and crafting usable systems.',
              'After years of software development, I became Director of Research for ten years for two money management firms, the second of which I was one of the founders. Since then, I have consulted to my former firm on special projects.',
              'I am now looking for a new home to apply my skills.',
              'In addition, I am also very interested in machining, and have developed some skills with milling machines, lathes, CNC, and other metalworking concerns.',
              'I can be contacted at dsmiley at sidorof com',
              'Additional projects found on [Github](https://github.com/sidorof)'
            ],
            image: { src: '', alt: '', height: '' }
          }
        }
      }
    ],
    technologies: {
      columns: [
        {
          title: 'Front-end',
          titleClass: 'subtitle-1 font-weighht-bold text-left text-break',
          items: [
            { name: 'Vue.js' },
            { name: 'Vuetify.js' },
            { name: 'D3.js' }
          ]
        },
        {
          title: 'Back-end',
          titleClass: 'subtitle-1 text-center text-break',
          items: [
            { name: 'Python' },
            { name: 'Flask' },
            { name: 'Django REST framework' },
            { name: 'Postgresql' },
            { name: 'RabbitMQ' },
            { name: 'Redis' },
            { name: 'Ansible' }
          ]
        },
        {
          title: 'Machine Learning',
          titleClass: 'subtitle-1 text-center text-break',
          items: [
            { name: 'Pytorch' },
            { name: 'TensorFlow' },
            { name: 'Sklearn' },
            { name: 'Deep Learning' },
            { name: 'Reinforcement Learning' },
            { name: 'Grammatical Evolution' }
          ]
        }
      ]
    },
    site: [
      {
        id: 0,
        columns: {
          0: {
            colWidth: 12,
            title: 'This Site',
            text: [
              'The front end uses Vue.js and Veutify.js.',
              'The back end uses Django REST framework.'
            ],
            image: { src: '', alt: '', height: '' }
          }
        }
      }
    ]
  }),
  mounted () {
    track({
      id: 'about',
      parameters: {
        locale: parameters.locale(),
        screenSize: parameters.screenType()
      }
    })
  }
}
</script>
