import Vue from 'vue'
import Vuex from 'vuex'
import { ACCESS_TOKEN } from '@/constants/params'
import { capitalize, getHeader, axiosError } from '@/lib/common'
import { TAGS_URL, ENTRIES_URL, ENTRIES_SUMMARY_URL } from '@/constants/urls'

Vue.use(Vuex)
// Vue.prototype.$http = axios
const axios = Vue.axios

/* This approach is taken to minimize the number of store modules.
 * Rather than a module for every type of object, an id is used
 * to indicate which object is being worked on at the time.
 * These objects are keyed by state.editId. That way it is possible
 * to maintain multiple edits if needed, but only one being worked on
 * at the time.
 * editId: the current edit object
 * edit: holds an object
 * editFlag: false/true for dialog
 *
 * Note that the id for queries is unhooked from ids for edits.
 */

const state = {
  // result of a query, stored by topic id
  queryResult: {},

  // saved record returned. expected to be one item
  savedResult: null,

  editId: null,
  edit: {},
  editFlag: {}
}

export const mutations = {
  // payload.id is the key that identifies the object in store
  clearQueryResult (state, payload) {
    delete state.queryResult[payload.id]
  },
  setSavedResult (state, payload) {
    state.savedResult = payload
  },
  clearSavedResult (state) {
    state.savedResult = null
  },
  setQryResult (state, payload) {
    /* Example of result from django
     * id tag is added
     * {
     *  "count":6,
     *  "next":null,
     *  "previous":null,
     *  "results":[
     *    { "id":1,"tag":"metalworking" },
     *    { "id":2,"tag":"neural_networks" },
     *    { "id":3,"tag":"openscad" },
     *    { "id":4,"tag":"machine_learning" },
     *    { "id":5,"tag":"cnc"},
     *    { "id":6,"tag":"python" }
     *   ]
     * }
     */
    if (payload.id === undefined) {
    }
    Vue.set(state.queryResult, payload.id, payload.data)
  },

  setEdit (state, payload) {
    Vue.set(state.edit, state.editId, payload)
  },

  setEditFlag (state, payload) {
    Vue.set(state.editFlag, state.editId, payload.value)
  },

  clearEdit (state, payload) {
    // uses payload to account for multiple edit sessions
    delete state.edit[payload.id]
  },

  setEditId (state, payload) {
    state.editId = payload.id
  },

  clearEditId (state) {
    state.editId = null
  }
}

export const actions = {

  clearEditId ({ commit }) {
    commit('clearEditId')
  },
  setEditId ({ commit }, payload) {
    commit('setEditId', payload)
  },

  clearEdit ({ commit }, payload) {
    commit('clearEdit', payload)
  },

  setEdit ({ commit }, payload) {
    commit('setEdit', payload)
  },

  setEditFlag ({ commit }, payload) {
    commit('setEditFlag', payload)
  },

  clearQueryResult ({ commit }, payload) {
    // payload must have an id
    commit('clearQueryResult', payload)
  },

  loadQuery ({ commit }, payload) {
    /* anatomy of payload
     * payload.id
     * payload.url
     * payload.params if GET
     *
     * payload.name if collection (removed in mutation)
     * payload.destination  mutation if other than qryResult
     *
     * payload.auth (true/false) (not used now)
     */
    commit('flag/setLoading', true, { root: true })
    commit('flag/clearError', null, { root: true })
    commit('clearQueryResult', payload)

    axios({
      method: 'get',
      url: payload.url,
      params: payload.params,
      headers: getHeader(localStorage.getItem(ACCESS_TOKEN))
    })
      .then(response => {
        if (payload.mutation !== undefined) {
          commit(
            payload.mutation,
            { id: payload.id, data: response.data },
            { root: true }
          )
        } else {
          commit(
            'setQryResult',
            { id: payload.id, data: response.data }
          )
        }
      })
      .catch(function (error) {
        axiosError(commit, error)
      })
    commit('flag/setLoading', false, { root: true })
  },
  clearSavedResult ({ commit }) {
    commit('clearSavedResult')
  },
  saveEdit ({ commit }, payload) {
    commit('flag/setLoading', true, { root: true })
    commit('flag/clearError', null, { root: true })
    commit('clearSavedResult')
    let method
    if (payload.data.id !== null && payload.data.id !== undefined) {
      method = 'put'
    } else {
      method = 'post'
    }

    const url = method === 'put'
      ? payload.url + '/' + payload.data.id + '/'
      : payload.url

    axios({
      method: method,
      url: url,
      data: payload.data,
      headers: getHeader(localStorage.getItem(ACCESS_TOKEN))
    })
      .then(response => {
        commit('setSavedResult', response.data)
      })
      .catch(error => {
        alert(error)
        axiosError(commit, error)
      })
  },
  dbDelete ({ commit }, payload) {
    commit('flag/setLoading', true, { root: true })
    commit('flag/clearError', null, { root: true })

    axios({
      method: 'delete',
      url: payload.url,
      params: payload.params,
      headers: getHeader(localStorage.getItem(ACCESS_TOKEN))
    })
      .then(response => {})
      .catch(function (error) {
        axiosError(commit, error)
      })
  },
  loadTags ({ commit, dispatch }) {
    dispatch(
      'loadQuery',
      {
        id: 'tags',
        url: TAGS_URL,
        params: {},
        mutation: 'entry/setTags'
      }
    )
  },
  loadEntriesTags ({ commit, dispatch }) {
    dispatch(
      'loadQuery',
      {
        id: 'entriesSummary',
        url: ENTRIES_SUMMARY_URL,
        params: {},
        mutation: 'entry/setEntriesSummary'
      }
    )
    dispatch(
      'loadQuery',
      {
        id: 'tags',
        url: TAGS_URL,
        params: {},
        mutation: 'entry/setTags'
      }
    )
  },
  deleteEntry ({ commit, dispatch }, payload) {
    dispatch(
      'dbDelete',
      {
        url: ENTRIES_URL,
        params: payload
      }
    )
    dispatch(
      'loadQuery',
      {
        id: 'entries',
        url: ENTRIES_URL,
        params: {},
        mutation: 'entry/setEntries'
      }
    )
  }
}

export const getters = {

  getQueryResult: (state) => (payload) => {
    return state.queryResult[payload.id]
  },
  getSavedResult (state) {
    return state.savedResult
  },
  getEdit (state) {
    return state.edit[state.editId]
  },
  getEditFlag (state) {
    return state.editFlag[state.editId]
  },
  getQueryList: (state, getters) => (payload) => {
    const result = getters.getQueryResult({ id: payload.id })
    // found in collection API
    if (result !== null && result !== undefined) {
      const className = Object.keys(result)[0]
      const funcs = (payload.funcs !== undefined)
        ? payload.funcs
        : {}

      const lines = result[className]

      const tmp = []
      for (let i = 0; i < lines.length; i++) {
        const line = lines[i]
        for (const key in payload.funcs) {
          const func = funcs[key]
          if (line[key] !== undefined) {
            line[key] = func(line[key])
          }
        }
        tmp.push(line)
      }
      return tmp
    }
    return null
  },

  getHeaders: (state, getters) => (payload) => {
    // strips out secondary objects
    const actions = payload.actions !== undefined

    const tmp = []
    const rows = getters.getQueryList({ id: payload.id })
    if (rows !== null && rows !== undefined) {
      if (rows.length > 0) {
        const row = rows[0]
        let columns = Object.keys(row)
        if (payload.columns !== undefined && payload.columns !== '') {
          columns = payload.columns.split(',')
        }

        for (const i in columns) {
          const key = columns[i].trim()
          const value = row[key]
          if (typeof value !== 'object' || value === null) {
            tmp.push({ text: capitalize(key), value: key })
          }
        }
        if (actions) {
          tmp.push({ text: 'Actions', value: 'actions', sortable: false })
        }
      }
    }
    return tmp
  },

  getNonHeaders: (state, getters) => (payload) => {
    // only secondary objects
    const tmp = []
    const rows = getters.getQueryList({ id: payload.id })
    if (rows !== null && rows !== undefined) {
      if (rows.length > 0) {
        const row = rows[0]
        let columns = Object.keys(row)
        if (payload.columns !== undefined && payload.columns !== '') {
          columns = payload.columns.split(',')
        }
        for (const i in columns) {
          const key = columns[i].trim()
          const value = row[key]
          if (typeof value === 'object') {
            tmp.push({ text: capitalize(key), value: key })
          }
        }
      }
    }
    return tmp
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
