// common functions
// NOTE: at some point this needs to be split up

export const capitalize = (words) => {
  var tmpWords = ''
  if (words !== null && words !== undefined) {
    const tmp = words.trim()
    tmpWords += tmp.trim().slice(0, 1).toUpperCase()
    if (tmp.length > 0) {
      for (var i = 1; i < tmp.length; i++) {
        var char = tmp.slice(i, i + 1)
        if (tmp.slice(i, i - 1) === ' ') {
          tmpWords += char.toUpperCase()
        } else {
          tmpWords += char
        }
      }
    }
  }
  return tmpWords
}

export const getHeader = (accessToken) => {
  const header = {
    'X-Api-Key': process.env.VUE_APP_API_KEY,
    'Content-type': 'application/json'
  }
  if (accessToken !== null && accessToken !== undefined) {
    header.Authorization = 'token_obtain ' + accessToken
  }
  return header
}

export const axiosError = (commit, error) => {
  // standard approach to axios errors
  if (error.response) {
    /*
    * The request was made and the server responded with a
    * status code that falls out of the range of 2xx
    */
    commit('flag/setLoading', false, { root: true })
    commit(
      'flag/setError',
      { message: error.response.data },
      { root: true }
    )
  } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    commit(
      'flag/setError',
      {
        message: 'The system is currently down. Please try again later.'
      },
      { root: true }
    )
  } else {
    // Something happened in setting up the request and triggered an Error
    commit(
      'flag/setError',
      { message: 'The system is currently down. Please try again later.' + error }, { root: true }
    )
    // this happens also if api is down
  }
}

export const disableSearch = (searchParams) => {
  // true means disabling search button
  var status = true
  for (var key in searchParams) {
    const value = searchParams[key]
    if (Array.isArray(value)) {
      if (value.length > 0) {
        status = false
      }
    } else {
      status = false
    }
  }
  return status
}

export const getDfltSection = (idx, cols) => {
  idx = idx !== undefined ? idx : -1
  cols = cols !== undefined ? cols : 2

  const section = {
    idx: idx,
    columns: {}
  }

  for (let i = 0; i < cols; i++) {
    section.columns[i] = {
      colWidth: 6,
      title: '',
      text: [],
      image: {
        alt: '',
        src: '',
        height: '500px'
      }
    }
  }
  return section
}
