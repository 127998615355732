/* projects.js
 *
 * For a quick list of projects.
 *
 */

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  /* {
   *  name: '',
   *  description: '',
   *  img: '',
   *  to: ''
   *  repositoryUrl: ''
   * }
   */
  projects: [
    {
      name: 'Vue-Meta-Info',
      description: [
        'This project provides a sample front-end for examining meta information from a Flask-RESTful-DBBase based application.',
        'The point of Flask-RESTful-DBBase derives from introspection of Python database models created with SQLAlchemy. By using the structure of the models, the constraints and relationships, a set of tools help validate, serialize/deserialize and document a web API.'
      ],
      to: '/projects/vue-meta-info',
      repositoryUrl: 'https://github.com/sidorof/vue-meta-info'
    }
  ]
}

const mutations = {}
const actions = {}
const getters = {
  getProjects (state) {
    return state.projects
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
