import store from '../store'

export default (to, from, next) => {
  const user = store.getters['auth/user']
  if (user) {
    next()
  } else {
    next('/signin')
  }
}
