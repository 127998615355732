<template>
  <v-container>
    <v-col cols="12" offset-md="1" md="10">
    <v-card>
      <v-breadcrumbs
      v-if="breadcrumb.show"
        :items="breadcrumb.items"
        divider=">"
      ></v-breadcrumbs>
    </v-card>
    <div v-if="entry !== undefined">
      <v-card>
        <div class="d-flex justify-center mb-6">
        <Tags :tags="entry.tags"/>
        </div>
        <div
          v-for="(section, n) in sectionList"
          :key="n"
          class="mt-1"
        >
          <entry-section :sectionData="section" />
        </div>
      </v-card>
    </div>
    <div v-if="entry === undefined">
      <v-card>
        <v-card-title>
          Entry {{ entryId }} is not found
        </v-card-title>
      </v-card>
    </div>
    </v-col>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { ENTRIES_URL } from '@/constants/urls'
import EntrySection from './EntrySection'
import Tags from './Tags'

export default {
  name: 'Entry',
  props: ['entryId', 'sectionLimit'],
  components: { EntrySection, Tags },

  data: () => ({
    breadcrumb: {
      show: false,
      items: [
        {
          text: 'home',
          disabled: false,
          to: '/'
        },
        {
          text: '',
          disabled: true,
          to: '/'
        }
      ]
    }
  }),

  mounted () {
    if (this.entry === undefined && this.entryId !== undefined) {
      this.loadQuery(
        {
          id: 'entries',
          url: ENTRIES_URL + this.entryId,
          params: {},
          mutation: 'entry/setEntry'
        }
      )
      this.loadTags()
    }
    this.setBreadCrumbs(this.entry)
  },

  watch: {
    entry (val) {
      if (val !== null && val !== undefined) {
        this.setBreadCrumbs(val)
      }
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      getEntry: 'entry/getEntry'
    }),
    entry () {
      return this.getEntry({ id: this.entryId })
    },
    sectionList () {
      const tmp = []
      const sections = JSON.parse(JSON.stringify(this.entry.article))
      if (this.sectionLimit === undefined) {
        // take all
        for (const key in sections) {
          tmp.push(sections[key])
        }
      } else {
        let count = 0
        for (const key in sections) {
          tmp.push(sections[key])

          count += 1
          if (count > this.sectionLimit) {
            break
          }
        }
      }
      return tmp
    }
  },
  methods: {
    ...mapActions({
      loadEntry: 'qry/loadEntry',
      loadTags: 'qry/loadTags',
      loadQuery: 'qry/loadQuery'
    }),
    setBreadCrumbs (entry) {
      if (entry !== null && entry !== undefined) {
        entry = JSON.parse(JSON.stringify(entry))
        this.breadcrumb.show = true
        this.breadcrumb.items[1].text = entry.title
        this.breadcrumb.items[1].to = '/' + entry.title
      }
    }
  }
}
</script>
